
import axios from 'axios';
import router from '@/router';
import { OrderCalculationService } from '@/services/OrderCalculationService';
import { v4 } from 'uuid';
import LocalStorageService from '@/services/LocalStorageService';
import RoleService from '@/services/RoleService';
import createDiscountPopoverService from '@/services/createDiscountPopoverService';
import QuoteForm from '@/components/QuoteForm.vue';
// @ts-ignore
import { sortBy } from 'lodash';
// @ts-ignore
import html2pdf from 'html2pdf.js';
import QuoteService from '@/services/QuoteService';
import { defineComponent } from 'vue';
import { getItemType } from '@/helper/products';
import { parseDueDate } from '@/helper/Helpers';

interface ComponentData {
  customerValue: string;
  showSelectCustomerModal: boolean;
  activateNotification: boolean;
  typeNotification: 'success' | 'error';
  titleNotification: string;
  messageNotification: string;
  PaymentType: {
    None: number;
    CreditCard: number;
    Cash: number;
  };
  ItemType: {
    None: number;
    Good: number;
    Service: number;
    Bundle: number;
  };
  DiscountType: {
    Percentage: number;
    Monetary: number;
  };
  TransactionType: {
    None: number;
    Sale: number;
    Void: number;
    Refund: number;
    Declined: number;
  };
  roleService: RoleService;
  localStorageService: LocalStorageService;
  createDiscountService: createDiscountPopoverService;
  quoteService: QuoteService;
  calcService: OrderCalculationService;
  calcResults: any;
  newOrder: any;
  newTransaction: any;
  selectableItems: any[];
  filteredItems: any[];
  searchTerm: string;
  iframeUrl: string;
  orderId: null | string;
  locations: any[];
  customers: any[];
  showZeroDollarErrorMessage: boolean;
  showConflictErrorMessage: boolean;
  savedPaymentMethods: any[];
  variantLocation: any[];
  isMarkAsPaidClicked: boolean;
  loading: boolean;
  isProductLoading: boolean;
  isCardPaymentProgress: boolean;
  isChargeDisabledNew: boolean;
  showDiscountButton: boolean;
  discount: any;
  barcodeValue: string;
  selectedCustomerId: string | null;
  selectedCustomer: string;
  selectedLocationId: string;
  customerOptions: any[];
  markedAsPaid: boolean;
  locationOptions: Array<{ value: string | number | null; text: string }>;
  showError: boolean;
  chargeDisabled: boolean;
  chargeInitiated: boolean;
  popoverShow: boolean;
  paymentType: any;
  payformVisible: boolean;
  savedPaymentFormVisible: boolean;
  disableLocationSelect: boolean;
  disableCustomerSelect: boolean;
  showCardOptions: boolean;
  isProcessingSavedCard: boolean;
  locationDiscounts: any[];
  showPayform: boolean;
  fields_quotes: {
    itemName: {
      label: string;
      key: string;
    };
    itemManufacturer: {
      label: string;
      key: string;
    };
    variantName: {
      label: string;
      key: string;
    };
    itemType: {
      label: string;
      key: string;
    };
    variantSku: {
      label: string;
      key: string;
    };
    variantPrice: {
      label: string;
      formatter: (price: any) => void;
    };
    variantQuantity: {
      label: string;
      key: string;
    };
    variantUpc: {
      label: string;
      key: string;
    };
  };
  fields: {
    itemName: {
      label: string;
      key: string;
    };
    variantPrice: {
      label: string;
      formatter: (price: any) => void;
    };
    variantQuantity: {
      label: string;
      class: string;
    };
    remove: {
      thClass: string;
      class: string;
      label: string;
    };
  };
  iFrameUrl2: string;
  showCustomer: boolean;
  url_parameters: string;
}

export default defineComponent({
  name: 'Quote',
  components: {
    QuoteForm,
  },
  data() {
    const data: ComponentData = {
      customerValue: '',
      showSelectCustomerModal: false,
      activateNotification: false,
      typeNotification: 'success',
      titleNotification: '',
      messageNotification: '',
      PaymentType: {
        None: 0,
        CreditCard: 1,
        Cash: 2,
      },
      ItemType: {
        None: 0,
        Good: 1,
        Service: 2,
        Bundle: 3,
      },
      DiscountType: {
        Percentage: 0,
        Monetary: 1,
      },
      TransactionType: {
        None: 0,
        Sale: 1,
        Void: 2,
        Refund: 3,
        Declined: 4,
      },
      roleService: undefined,
      localStorageService: undefined,
      createDiscountService: undefined,
      quoteService: undefined,
      calcService: undefined,
      calcResults: undefined,
      newOrder: {},
      newTransaction: {},
      selectableItems: [],
      filteredItems: [],
      searchTerm: '',
      iframeUrl: '',
      orderId: null,
      locations: [],
      customers: [],
      showZeroDollarErrorMessage: false,
      showConflictErrorMessage: false,
      savedPaymentMethods: [],
      variantLocation: [],
      isMarkAsPaidClicked: false,
      loading: false,
      isProductLoading: false,
      isCardPaymentProgress: false,
      isChargeDisabledNew: false,
      showDiscountButton: false,
      discount: undefined,
      barcodeValue: '',
      selectedCustomerId: null,
      selectedCustomer: '',
      selectedLocationId: undefined,
      customerOptions: [],
      markedAsPaid: false,
      locationOptions: [{ value: null, text: 'Please select a location' }],
      showError: false,
      chargeDisabled: false,
      chargeInitiated: false,
      popoverShow: false,
      paymentType: undefined,
      payformVisible: false,
      savedPaymentFormVisible: false,
      disableLocationSelect: false,
      disableCustomerSelect: false,
      showCardOptions: false,
      isProcessingSavedCard: false,
      locationDiscounts: [],
      showPayform: false,
      fields_quotes: {
        itemName: {
          label: 'Item Name',
          key: 'itemName',
        },
        itemManufacturer: {
          label: 'Item manufacturer',
          key: 'itemManufacturer',
        },
        variantName: {
          label: 'Variant Name',
          key: 'variantName',
        },
        itemType: {
          label: 'Item Type',
          key: 'itemType',
        },
        variantSku: {
          label: 'SKU',
          key: 'variantSku',
        },
        variantPrice: undefined,
        variantQuantity: {
          label: 'Quantity',
          key: 'variantQuantity',
        },
        variantUpc: {
          label: 'UPC',
          key: 'variantUpc',
        },
      },
      fields: {
        itemName: {
          label: 'Item Name',
          key: 'itemName',
        },
        variantPrice: undefined,
        variantQuantity: {
          label: 'Quantity',
          class: 'w-50',
        },
        remove: {
          thClass: 'order-item-remove-lbl',
          class: 'remove-cart-item',
          label: '',
        },
      },
      iFrameUrl2: '',
      showCustomer: false,
      url_parameters: '',
    };
    return data;
  },
  created() {
    this.roleService = new RoleService(new LocalStorageService());
    this.localStorageService = new LocalStorageService();
    this.createDiscountService = new createDiscountPopoverService();
    this.quoteService = new QuoteService();
    this.calcService = new OrderCalculationService();
    this.selectedLocationId = this.localStorageService.getItem(
      'primary_location_id',
    );
    this.paymentType = this.PaymentType.None;
    this.variantPrice = {
      label: 'Price',
      formatter: (price: any) => {
        return this!.$options!.filters!.currency(price);
      },
    };
    this.variantPrice = {
      label: 'Price',
      formatter: (price: any) => {
        return this!.$options!.filters!.currency(price);
      },
    };
  },
  async mounted() {
    this.loading = true;
    if (this.$route.query.data) {
      const urldata: any = this.$route.query.data;
      this.newOrder = JSON.parse(window.atob(decodeURIComponent(urldata)));
    } else if (this.$route.query.id) {
      const quoteId: any = this.$route.query.id;
      const locationId: any = this.$route.query.locationId;
      const res = await this.quoteService.getQuoteDetails(quoteId, locationId);
      this.newOrder = res.data.quotes;
      this.newOrder.variantSnapshots = this.newOrder.variantQuoteSnapshots;
      this.newOrder.isBack = true;
      this.selectedLocationId = res.data.quotes.location.id;
      if (res.data.customer) {
        this.newOrder.customer = res.data.customer;
        this.customers = this.newOrder.customer;
      }
    } else {
      this.newOrder.isBack = true;
    }
    this.calcResults = this.calcService.calculateOrder(this.newOrder);
    if (this.newOrder.isBack && this.$route.query.data) {
      await html2pdf()
        .set({
          margin: 1,
          mode: 'legacy',
          filename: this.getuuid() + '.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        })
        .from(document.getElementById('element-to-print'))
        .save();
    } else if (this.$route.query.id) {
      this.showCustomer = true;
    } else {
      await html2pdf()
        .set({
          margin: 1,
          mode: 'legacy',
          filename: this.getuuid() + '.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        })
        .from(document.getElementById('element-to-print'))
        .output('datauristring')
        .then((res: any) => {
          // $this.router.push(res)
          document.write(
            '<iframe src="' +
              res +
              '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>',
          );
        });
      // window.location.href = window.pdfurl
    }
    this.loading = false;
  },
  methods: {
    parseDueDate,
    getItemType,
    currency(value: any) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(value);
      // return '$' + parseFloat(value).toFixed(2)
    },

    getPrice(price: any) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(price);
    },

    getCost(quantity: any, price: any) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(parseFloat(quantity) * parseFloat(price));
    },

    BackToOrder() {
      this.$router.push('/list-quotes');
    },

    getuuid() {
      if (this.newOrder) {
        if (this.newOrder.id && this.newOrder.id !== '') {
          return this.newOrder.id;
        } else {
          this.newOrder.id = v4();
          return this.newOrder.id;
        }
      }
    },

    async downloadPdf() {
      this.loading = true;
      await html2pdf()
        .set({
          margin: 1,
          mode: 'legacy',
          filename: this.getuuid() + '.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        })
        .from(document.getElementById('element-to-print'))
        .save();
      this.loading = false;
    },

    getcurrentdate() {
      return new Date().toJSON().slice(0, 10).replace(/-/g, '/');
    },

    tearDownListeners() {
      window.removeEventListener('message', this.payformListener, false);
    },

    destroyed() {
      this.tearDownListeners();
    },

    updateCalcs() {
      this.calcResults = this.calcService.calculateOrder(this.newOrder);
    },

    changeLocation() {
      this.calcResults.subtotal = 0;
      this.calcResults.total = 0;
      this.calcResults.tax = 0;
      this.calcResults.discountAmount = 0;
      this.newOrder.variantSnapshots = [];
      this.newOrder.location = this.getSelectedLocation();
      this.payformVisible = false;
      this.tearDownListeners();
      if (this.chargeInitiated) this.resetCharge();

      this.selectedCustomerId = null;
      this.getLocationDiscount();
      this.findItems();

      axios
        .get(
          `${process.env.VUE_APP_BASE_URL}/customer?locationId=${this.newOrder.location.id}`,
        )
        .then((res) => {
          this.customers = res.data.customer;
          this.setCustomerOptions();
        });
    },

    async addToOrder(item: any) {
      if (item.itemType === 3) {
        for (let i = 0; i < this.selectableItems.length; i++) {
          if (item.variantId === this.selectableItems[i].variantId) {
            const new_item: any = this.selectableItems[i];
            const isAdded1 = await this.checkItemExist(new_item);
            if (this.chargeInitiated) this.resetCharge();
            if (!isAdded1) {
              this.newOrder.variantSnapshots.push(new_item);
              this.updateCalcs();
            }
          }
        }
      } else {
        const isAdded = await this.checkItemExist(item);
        if (this.chargeInitiated) this.resetCharge();
        if (!isAdded) {
          this.newOrder.variantSnapshots.push(item);
          this.updateCalcs();
        }
      }
    },

    async checkItemExist(item: any) {
      for (let i = 0; i < this.newOrder.variantSnapshots.length; i++) {
        if (this.newOrder.variantSnapshots[i].variantId === item.variantId) {
          this.newOrder.variantSnapshots[i].variantQuantity =
            this.newOrder.variantSnapshots[i].variantQuantity + 1;
          this.updateCalcs();
          return true;
        }
      }
      return false;
    },

    clearCustomer() {
      this.customerValue = '';
      this.newOrder.customer = {};
    },

    removeFromOrder(item: any) {
      for (let i = this.newOrder.variantSnapshots.length - 1; i >= 0; i--) {
        if (this.newOrder.variantSnapshots[i] === item) {
          this.newOrder.variantSnapshots.splice(i, 1);
          this.resetCharge();
          this.updateCalcs();
          this.$emit('remove-from-order', item);
        }
      }
    },

    updateQuantity(item: any, type: string) {
      if (type === 'plus') {
        item.variantQuantity++;
      } else {
        item.variantQuantity--;
      }

      for (let i = 0; i < this.filteredItems.length; i++) {
        if (this.filteredItems[i].variantId === item.variantId) {
          if (item.variantStock) {
            this.filteredItems[i].variantStock =
              item.variantStock - item.variantQuantity;
          }
        }
      }
      this.updateCalcs();
    },

    useSavedCard() {
      this.tearDownListeners();

      this.payformVisible = false;
      this.savedPaymentFormVisible = true;
    },

    payformListener(event: any) {
      if (event && event.type === 'message') {
        const response = JSON.parse(event.data);
        this.checkForDecline(response);

        const transaction: any = {
          id: response.id,
          transaction_api_id: response.transaction_api_id,
          fortisTransactionId: response.id,
          orderId: this.orderId!,
        };

        this.newOrder.accountType = response.account_type;

        axios
          .post(`${process.env.VUE_APP_BASE_URL}/order`, this.newOrder)
          .then((apiResponse) => {
            if (
              apiResponse.status === 200 &&
              apiResponse.data.order !== undefined
            ) {
              const orderId2 = apiResponse.data.order.order.id;
              transaction.orderId = orderId2;
              transaction.order = apiResponse.data.order.order;
              this.orderId = orderId2;

              axios
                .put(
                  `${process.env.VUE_APP_BASE_URL}/transaction/${transaction.transaction_api_id}`,
                  transaction,
                )
                .then((res) => {
                  if (res.status === 200) {
                    this.tearDownListeners();

                    router.replace({
                      path: `/orders/${this.theOrderId}`,
                    });
                  }
                });
            } else if (apiResponse.status === 409) {
              this.showOrderConflictMessage();
            }
          });
      }
    },

    hasCartTotal() {
      if (this.calcResults.total === 0.0) {
        this.payformVisible = false;
        this.savedPaymentFormVisible = false;
        this.showZeroDollarErrorMessage = true;
        return false;
      } else {
        this.showZeroDollarErrorMessage = false;
      }
      return true;
    },

    async processCashOrder(): Promise<any> {
      let apiResponse;
      try {
        apiResponse = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/order`,
          this.newOrder,
        );
      } catch (err) {
        apiResponse = err.response;
        if (apiResponse.status === 409) {
          this.showOrderConflictMessage();
        }
      } finally {
        if (
          apiResponse.status === 200 &&
          apiResponse.data.order !== undefined
        ) {
          const orderId = apiResponse.data.order.order.id;
          this.markedAsPaid = true;
          router.push({ path: `/orders/${orderId}` });
        }
      }

      return apiResponse;
    },

    resetCharge() {
      this.newTransaction = undefined;
      this.savedPaymentMethods = [];
      this.showCardOptions = false;
      this.showPayform = false;
      this.payformVisible = false;
      this.savedPaymentFormVisible = false;
      this.chargeInitiated = false;
      this.tearDownListeners();
    },

    findItems(): void {
      this.isProductLoading = true;
      this.selectableItems = [];
      axios
        .get(
          `${process.env.VUE_APP_BASE_URL}/item?location=${this.selectedLocationId}`,
        )
        .then((res) => {
          if (res.data.items) {
            res.data.items.forEach(async (item: any) => {
              if (item.itemType === 3) {
                let price = 0;
                await item.bundles.forEach((bundle: any) => {
                  price += parseFloat(bundle.variant.price.toString());
                });
                this.selectableItems.push({
                  variantId: '',
                  variantName: '',
                  variantSku: item.bundles[0].bundleSKU,
                  itemHasImage: item.hasImage,
                  itemImageSrc: item.imageSrc,
                  variantPrice: price,
                  variantQuantity: 1,
                  itemDiscount: 0,
                  itemName: item.name,
                  itemType: item.itemType,
                  itemDescription: item.description,
                  itemManufacturer: item.manufacturer,
                  itemTaxes: item.taxes!,
                  itemId: item.id,
                  bundles: item.bundles,
                  variantStock:
                    item.variants[0].variantLocations[0].currentStock,
                  variantTracking:
                    item.variants[0].variantLocations[0]
                      .isInventoryTrackingEnabled,
                  variantUpc: item.bundles[0].bundleSKU,
                });
              } else {
                if (item.variants) {
                  item.variants.forEach((variant: any) => {
                    this.selectableItems.push({
                      variantId: variant.id!,
                      variantName: variant.name,
                      variantSku: variant.sku,
                      itemHasImage: item.hasImage,
                      itemImageSrc: item.imageSrc,
                      variantPrice: variant.price,
                      variantQuantity: 1,
                      itemDiscount: 0,
                      itemName: item.name,
                      itemType: item.itemType,
                      itemDescription: item.description,
                      itemManufacturer: item.manufacturer,
                      itemTaxes: item.taxes!,
                      itemId: item.id,
                      variantStock: variant.variantLocations[0].currentStock,
                      variantTracking:
                        variant.variantLocations[0].isInventoryTrackingEnabled,
                      variantUpc: variant.upc,
                      variantLocation: variant.variantLocations,
                    });
                  });
                }
              }
            });
            this.filteredItems = this.selectableItems;
            this.isProductLoading = false;
          }
        });
    },

    resetSearch() {
      this.searchTerm = '';
      this.filteredItems = sortBy(this.selectableItems, (o: any) => o.itemName);
    },

    conductSearch(event: string) {
      const search = new RegExp(event, 'gi');
      this.filteredItems = this.selectableItems.filter((item: any) => {
        return (
          item.itemName.match(search) ||
          (item.variantUpc && item.variantUpc.match(search)) ||
          (item.variantSku && item.variantSku.match(search)) ||
          (item.variantName && item.variantName.match(search)) ||
          (item.itemManufacturer && item.itemManufacturer.match(search))
        );
      });
    },

    barcodeSearch(event: string) {
      const search = new RegExp(event, 'gi');
      let found = 0;
      let founditem: any;
      this.filteredItems = this.selectableItems.filter((item, index) => {
        if (item.variantUpc === event) {
          found++;
          founditem = item;
        }
        if (index === this.filteredItems.length - 1 && found === 1) {
          this.addToOrder(founditem).then(() => {
            this.resetSearch();
          });
        }
        if (found > 1) {
          this.searchTerm = this.barcodeValue;
        }
        return (
          item.itemName.match(search) ||
          (item.variantUpc && item.variantUpc.match(search)) ||
          (item.variantSku && item.variantSku.match(search)) ||
          (item.variantName && item.variantName.match(search)) ||
          (item.itemManufacturer && item.itemManufacturer.match(search))
        );
      });
      if (found === 0) {
        this.activateNotification = true;
        this.typeNotification = 'error';
        this.titleNotification = 'error';
        this.messageNotification =
          'No product found with upc ' + this.barcodeValue;
        this.resetSearch();
      }
    },

    getSelectedLocation() {
      const locationId = this.selectedLocationId;
      return this.locations.filter((l: any) => l.id === locationId)[0];
    },

    getSelectedCustomer(customer: any) {
      this.newOrder.customer = customer;
      this.customerValue = this.customerName() + ' ' + this.customerAccount();
      return customer;
    },

    setLocationOptions() {
      this.locationOptions = this.locationOptions.concat(
        this.locations.map((location: any) => {
          return { value: location.id, text: location.name };
        }),
      );
    },

    setCustomerOptions() {
      this.customerOptions = this.customers
        ? this.customers.map((customer: any) => {
            return {
              value: customer.id,
              text: this.getCustomerLabel(customer),
            };
          })
        : [];
    },

    getCustomerLabel(customer: any): string {
      return `${customer.first_name} ${customer.last_name} - Acc #: ${customer.account_number}`;
    },

    customerAddress() {
      return this.newOrder.customer && this.newOrder.customer.address
        ? this.newOrder.customer.address
        : '';
    },

    customerName() {
      let firstName = '';
      if (this.newOrder.customer) {
        firstName =
          this.newOrder.customer.first_name == null
            ? ''
            : this.newOrder.customer.first_name;
      }
      return this.newOrder.customer
        ? firstName + ' ' + this.newOrder.customer.last_name
        : '';
    },

    customerAccount() {
      return this.newOrder.customer
        ? '#' + this.newOrder.customer.account_number == null
          ? ''
          : this.newOrder.customer.account_number
        : '';
    },

    customerEmail() {
      return this.newOrder.customer ? this.newOrder.customer.email : '';
    },

    locationAddress() {
      return this.newOrder.location &&
        this.newOrder.location.address1 &&
        this.newOrder.location.address2 &&
        this.newOrder.location.city
        ? this.newOrder.location.address1 +
            ',' +
            this.newOrder.location.address2 +
            ',' +
            this.newOrder.location.city
        : '';
    },

    locationName() {
      return this.newOrder.location ? this.newOrder.location.name : '';
    },

    locationEmail() {
      return this.newOrder.location ? this.newOrder.location.email : '';
    },

    async submitPaymentWithSavedCard(accountVault: any) {
      const fortisTransaction: any = {
        transaction_api_id: this.newTransaction,
        transaction_amount: this.calcResults.total,
        account_vault_id: accountVault.id,
        location_id: this.selectedLocationId,
      };

      this.isProcessingSavedCard = true;
      let fortis_res = null;
      let error;
      const _vm = this;
      fortis_res = await axios
        .post(
          `${process.env.VUE_APP_BASE_URL}/fortis/transaction`,
          fortisTransaction,
        )
        .catch(function (err) {
          error = false;
          _vm.activateNotification = true;
          _vm.typeNotification = 'error';
          _vm.titleNotification = 'error';
          _vm.messageNotification = `Communication Error \n Status Code: ${err.response.status}`;
        });

      this.isProcessingSavedCard = error ? false : true;

      if (fortis_res && fortis_res.status === 201) {
        this.checkForDecline(fortis_res.data.transaction);

        const transaction: any = {
          id: fortis_res.data.transaction.id,
          transaction_api_id: fortis_res.data.transaction.transaction_api_id,
          fortisTransactionId: fortis_res.data.transaction.id,
          orderId: this.orderId!,
        };
        let order_res;
        const _vm = this;
        this.newOrder.accountType = fortis_res.data.transaction.account_type;
        try {
          order_res = await axios.post(
            `${process.env.VUE_APP_BASE_URL}/order`,
            this.newOrder,
          );
        } catch (err) {
          order_res = err.response;
          if (err.response.status === 409) {
            this.showOrderConflictMessage();
          }
          error = true;
          _vm.activateNotification = true;
          _vm.typeNotification = 'error';
          _vm.titleNotification = 'Error';
          _vm.messageNotification = `Communication Error \n Status Code: ${err.response.status}`;
        } finally {
          if (order_res.status === 200 && order_res.data.order !== undefined) {
            const orderId = order_res.data.order.order.id;
            transaction.orderId = orderId;
            transaction.order = order_res.data.order.order;
            const _vm = this;
            const trx_res = await axios
              .put(
                `${process.env.VUE_APP_BASE_URL}/transaction/${transaction.transaction_api_id}`,
                transaction,
              )
              .catch(function (trx_err) {
                error = true;
                _vm.activateNotification = true;
                _vm.typeNotification = 'error';
                _vm.titleNotification = 'Error';
                _vm.messageNotification = `Communication Error \n Status Code: ${trx_err.response.status}`;
              });

            if (trx_res && trx_res.status === 200) {
              this.tearDownListeners();
              router.replace({
                path: `/orders/${this.theOrderId}`,
              });
            }
          }
        }
        this.isProcessingSavedCard = error ? false : true;
      }
    },

    checkForDecline(transaction: any) {
      const fortisDeclinedStatusId = 301;
      if (transaction.status_id === fortisDeclinedStatusId) {
        this.newOrder.transactionType = this.TransactionType.Declined;
      }
    },

    showOrderConflictMessage(): void {
      this.payformVisible = false;
      this.savedPaymentFormVisible = false;
      this.showConflictErrorMessage = true;
    },

    addDiscount(discount: any) {
      if (this.chargeInitiated) this.resetCharge();
      this.popoverShow = false;
      this.showDiscountButton = false;

      const newDiscount: any = {
        name: discount.name,
        value: discount.value,
        discountType: discount.discountType,
        id: discount.id,
        orderId: discount.orderId,
        friendlyDisplay: '',
      };

      if (discount.discountType === this.DiscountType.Percentage) {
        newDiscount.friendlyDisplay = `${discount.value}% off all items`;
      } else if (discount.discountType === this.DiscountType.Monetary) {
        newDiscount.friendlyDisplay = `$${discount.value} off all items`;
      }

      this.discount = newDiscount;
      this.newOrder.discounts!.push(newDiscount);
      this.updateCalcs();
    },

    closeDiscountPopover() {
      this.popoverShow = false;
    },

    removeDiscount() {
      if (this.chargeInitiated) this.resetCharge();
      this.discount = undefined;
      this.showDiscountButton = true;
      this.newOrder.discounts!.pop();
      this.updateCalcs();
    },

    async getLocationDiscount() {
      const response: any =
        await this.createDiscountService.getLocationDiscount(
          this.selectedLocationId,
        );
      this.locationDiscounts = response;
    },
  },
  computed: {
    iFrameUrl() {
      return this.iframeUrl;
    },

    isBrowseDisabled(): boolean {
      return this.selectedLocationId === null;
    },

    isChargeDisabled(): boolean {
      return (
        this.newOrder.variantSnapshots.length === 0 ||
        this.chargeInitiated === true ||
        this.isChargeDisabledNew
      );
    },

    isMarkAsPaidDisabled(): boolean {
      return this.newOrder.variantSnapshots.length === 0;
    },

    theOrderId() {
      return this.orderId;
    },
    getName() {
      const { newOrder } = this;
      return newOrder && newOrder.quote_name !== undefined
        ? newOrder.quote_name
        : '';
    },
    getDueDate() {
      const { newOrder } = this;
      const dateObject = new Date(newOrder.dueDate);
      const year = dateObject.getFullYear();
      const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
      const day = dateObject.getDate().toString().padStart(2, '0');
      return newOrder && newOrder.dueDate !== undefined
        ? `${year}/${month}/${day}`
        : '';
    },

    getQuoteInfo() {
      if (this.newOrder) {
        const { newOrder } = this;
        const dateObject = new Date(newOrder?.dueDate);
        const year = dateObject.getFullYear();
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObject.getDate().toString().padStart(2, '0');
        const firstName = this.newOrder.customer?.first_name
          ? this.newOrder.customer?.first_name
          : '';
        const lastName = this.newOrder.customer?.last_name
          ? this.newOrder.customer?.last_name
          : '';
        const customerName = `${firstName} ${lastName}`;
        const phone = this.newOrder.customer?.cell_phone;
        const email = this.newOrder.customer?.email;

        return {
          name: this.newOrder.quote_name,
          dueDate: `${year}/${month}/${day}`,
          customerName,
          phone,
          email,
        };
      }
      return '';
    },

    isDiscountDisabled(): boolean {
      return this.newOrder.variantSnapshots.length === 0;
    },
  },
});
